//light theme variable
$eclipse: #3F3F3F;
$bg: #F3F3F3;
$white-smoke: #F8F8F8;
$white: #FFF;
$grey-bg: #F1F1F1;
$night-rider-bg: #EBEDF0;
$sun-bg: #FCF3EB;
$silver: #BEBEBE;
$nobel: #9B9B9B;
$grey: #757575;
$dark-gray: #AEAEAE;
$dark-gray-dull: #EAEAEA;
$dove-gray: #727272;
$dim-gray: #636363;
$night-rider: #313131;
$royal-blue-bg: #9ab0fc1a;
$fern-bg: #9cd79e1a;
$royal-blue: #3058E3;
$selective-yellow: #FFB100;
$selective-yellow-2: #FFBF2E;
$lightning-yellow: #FFBB20;
$safron: #FFbF2F;
$sun: #F39231;
$fern: #50CB50;
$submenu-bg: rgba(255, 177, 0, 0.08);
$lightning-yellow-bg: rgba(255, 187, 32, 0.10);
$very-light-grey: #EFEFEF;
$light-grey: #C7C7C7;
$snow: #FBFBFB;
$geraldine: #EC7373;
$early-dawn: #FFF4DB;
$error-bg: #f46a6a1a;
$black-disabled: #0000006e;
$light-black: #000000de;
$green-apple: #68C155;
$mahogany: #DF3E40;
$matterhorn: #4D4D4D;
$gainsboro: #E3E3E3D1;
$traffic-white: #F6F6F6;
$blue: blue;
$placeholdertxt: #BCBCBC;
$mappinghover: #F5F5F5;
$badge-grey-bg: #F4F4F4;
$badge-grey: #858585;
$grey-94: #F0F0F0;
$alabaster: #FAFAFA;
$shadow: #0000001a;
$labelBlack: #393939;
$labelWhite:#E7E7E7;
$overlay:#00000017;
$imgOverlay:#0c0b0b8a;
$toggleBackground:#ccc;
$selective-yellow-dull:#ffb100b8;
$date-shadow: #8798ad;
$black: #000;

//dark theme variable
$eclipse-dark: #fff;
$bg-dark: #1b1b1b;
$white-dark: #0c0c0c;
$dim-gray-dark: #fff;
$grey-bg-dark: #3b3b3b;