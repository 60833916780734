.custom-input {
    height: 50px;
    background-color: var(--snow);
    border: 0;
    border-radius: 10px;
    padding: 0 20px;
    border: 1px solid transparent;

    &:focus {
        background-color: var(--snow);
        box-shadow: unset;
        border: 1px solid var(--blue);
    }

    &:focus-visible {
        outline: 0;
    }

    &.error-input {
        background-color: var(--error-bg) !important;
        border: 1px solid var(--mahogany);
    }
}

.form-label {
    color: var(--dove-gray);
    margin-bottom: 11px;

    &.required {
        &::after {
            content: '*';
            color: var(--geraldine);
        }
    }
}

.invalid-feedback {
    color: var(--mahogany);
    text-align: left;
    position: relative;
    padding-left: 20px;

    &::before {
        background: url(../../images/svgIcons/alert_circle.svg) no-repeat;
        background-size: cover;
        content: "";
        height: 14px;
        left: 0;
        position: absolute;
        top: 0;
        width: 14px;
    }
}

//file upload 

.file-upload {
    text-align: center;
    border: 2px dashed var(--silver);
    padding: 1.5rem;
    position: relative;
    cursor: pointer;
    border-radius: 12px;
    height: 90%;
    @include align-justify-center;

    .upload-img {
        border-radius: 50%;
        background-color: var(--early-dawn);
        width: 55px;
        height: 55px;
        @include align-justify-center;
    }

    .sub {
        color: var(--grey);
        font-weight: $fw-semibold;
        margin: 10px 0;
    }

    .info {
        color: var(--dark-gray);
        font-size: $fs-micro;
        font-weight: $fw-medium;
    }

    input {
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        cursor: pointer;
    }

    .file-img-wrapper {
        @include align-center-justify-between;
        border: 1px solid var(--silver);
        border-radius: 15px;
        padding: 10px 15px;
        margin-top: 15px;
        position: relative;
        z-index: 1;

        .img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            border: 2px solid var(--silver);
            @include align-justify-center;

            img {
                width: 100%;
            }
        }

        .file-name {
            margin: 0 15px;
            color: var(--grey);
        }

        .cancel {
            cursor: pointer;
        }
    }
}

.password-input {
    position: relative;

    input {
        padding-right: 45px;
    }

    .eye-img {
        position: absolute;
        top: 15px;
        right: 12px;
        cursor: pointer;
    }
}

.input-wrapper {
    margin-bottom: 25px;
}

//text area
textarea {
    &.custom-input {
        height: 151px;
        resize: none;
        padding: 15px 20px;
    }
}


//Check Box
.form-check-input {
    width: 15px;
    height: 15px;
    border-radius: 50px !important;
    border-color: $selective-yellow;
    // background: transparent;
    cursor: pointer;

    &:checked {
        background-color: $selective-yellow;
        border-color: $selective-yellow;
        transition: all 0.2s ease-in-out;
    }

    &:focus {
        box-shadow: none;
    }
}

.search {
    @include align-center-justify-end;
    width: 100%;
    position: relative;

    .filter-search {
        height: 40px;
        width: 0;
        transition: all 0.3s;
        padding: 0;
        position: absolute;
        top: -29px;

        &:focus {
            width: 100%;
            transition: all 0.3s;
            border: 1px solid var(--blue);
            padding: 0 38px 0 20px;
        }

        &:focus-visible {
            outline: 0;
        }
    }

    .search-icon {
        position: absolute;
        right: 10px;
        top: -18px;
        cursor: pointer;

        img {
            width: 17px;
            height: 17px;
        }
    }
}

.input-search {
    position: relative;

    .custom-input {
        height: 38px;
        width: 100%;
        border-radius: 8px;
        background-color: var(--alabaster);
        padding-right: 27px;
    }

    .search-icon {
        position: absolute;
        right: 8px;
        top: 11px;
        cursor: pointer;
    }
}


//Table Search
.table-search {
    margin-bottom: 25px;

    .search {
        .filter-search {
            width: 250px;
            position: unset;
            transition: unset;
            padding: 0 20px;
            padding-right: 40px;

            &:focus {
                width: 250px;
            }
        }

        .search-icon {
            right: 15px;
            top: 12px;
        }
    }
}