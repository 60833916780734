// 
// _table.scss
// 
.table> :not(caption)>*>* {
    padding: 0;
    vertical-align: middle;
    background-color: transparent;
}

.custom-table {
    overflow-y: hidden;
    background: var(--white);
    padding: 15px 0 8px 0;
    border-radius: 10px;

    table {
        margin-bottom: 0;
        position: relative;

        thead {
            border-bottom: 1px solid var(--very-light-grey);

            tr {
                th {
                    color: var(--dim-gray);
                    font-size: $fs-normal;
                    font-weight: $fw-medium;
                    padding-bottom: 15px;
                    min-width: 170px;

                    .descending {
                        transform: rotate(180deg);
                    }

                    .cell-head {
                        padding: 0 10px;
                    }

                    &:first-child {
                        padding-left: 21px;
                    }

                    &:last-child {
                        padding-right: 21px;
                    }

                    .filters {
                        @include align-center-justify-end;
                        gap: 17px;

                        .search {
                            .filter-search {
                                position: unset;

                                &:focus {
                                    width: 200px;
                                }
                            }

                            .search-icon {
                                top: 10px;
                            }
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid var(--snow);
                &:last-child {
                    border-bottom: none;
                }
                td {
                    color: var(--dim-gray);

                    .cell-data {
                            padding: 7px 10px;
                            line-height: 20px;
                            font-size: $fs-small;
                    
                            .allow-max-width {
                                width: max-content;
                            }
                    }

                    &:first-child {
                        padding-left: 21px !important;
                    }

                    &:last-child {
                        padding-right: 21px !important;
                    }

                    &::first-letter {
                        text-transform: capitalize;
                    }
                }

                &:hover {
                    background-color: var(--snow);
                }

                &.opened {
                    background-color: var(--traffic-white);

                    +tr {
                        background-color: var(--traffic-white);
                    }
                }

                &.is-expanded {
                    &:not(:first-child) {
                        border-top: 1px solid var(--white);
                    }

                    &.opened {
                        .arrow {
                            img {
                                transform: rotate(180deg);
                                transition: all 0.3s;
                            }
                        }
                    }
                }

                &.sub-row {
                    .sub-row-details {
                        &.expanded {
                            border-top: 1px solid var(--very-light-grey);
                        }

                        .cell-data {
                            padding: 10px 21px;

                            &.close {
                                display: none;
                            }

                            &.open {
                                animation: scale-display 0.3s;
                                display: block;
                            }
                        }
                    }
                }

                .arrow {
                    @include align-center-justify-end;
                }
            }
        }
    }
}

.description-cell {
    line-height: 21px;
}

@keyframes scale-display {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}