.auth {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: var(--white);

    .auth-wrapper {
        .auth-img {
            position: relative;
            text-align: center;
            background-color: var(--bg);
            height: 100dvh;

            .logo {
                position: absolute;
                top: 50px;
                left: 50px;

                // img {
                //     width: 289px;
                // }
            }

            .auth-img-wrapper {
                display: flex;
                justify-content: center;
                align-items: end;
                height: 100%;

                .full-img {
                    max-width: 100%;
                    transform: scale(1.1);
                    padding: 0 20px;
                }
            }

            .about-desc {
                width: 65%;
                color: var(--white);
                position: absolute;
                bottom: 80px;
                left: 76px;

                .heading {
                    font-size: $fs-XXXL;
                    font-weight: $fw-semibold;
                    margin-bottom: 15px;
                }

                .desc {
                    font-size: $fs-h4-big;
                }
            }
        }

        .auth-form {
            @include align-justify-center;

            .form-wrapper {
                width: 75%;

                .logo-container {
                    text-align: center;
                    display: none;
                }

                .auth-header {
                    .page-title {
                        font-size: $fs-XXL;
                        line-height: 48px;
                    }

                    .desc {
                        line-height: 24px;
                    }

                    margin-bottom: 20px;
                    padding-bottom: 20px;
                }

                .change-action {
                    font-size: $fs-small;

                    a {
                        line-height: 18px;
                        text-decoration: none;
                        color: var(--matterhorn);
                    }
                }

                .actions {
                    padding-top: 24px;
                    padding-bottom: 24px;
                    margin-top: 24px;

                    .custom-btn {
                        width: 100%;
                        height: 60px;
                        font-weight: $fw-medium;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1366px) {
    .auth {    
        .auth-wrapper {
            .auth-img {
                .auth-img-wrapper {    
                    .full-img {
                        transform: scale(1);
                        width: 75%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .auth {
        height: auto;

        .auth-wrapper {
            .auth-img {
                display: none;
            }

            .auth-form {
                width: 100%;
                min-height: 100vh;

                .form-wrapper {
                    width: 500px;

                    .logo-container {
                        display: block;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .auth {
        .auth-wrapper {
            .auth-form {
                .form-wrapper {
                    width: 80%;
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .auth {
        .auth-wrapper {
            .auth-form {
                .form-wrapper {
                    .actions {
                        padding-top: 0;
                        padding-bottom: 15px;

                        .custom-btn {
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}