.toggle-switch {
    display: inline-block;
    background: var(--toggleBackground);
    border-radius: 16px;
    width: 42px;
    height: 24px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
    cursor: pointer;

    &:before {
        content: "";
        display: block;
        background: var(--mahogany);
        border-radius: 50%;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 4px;
        left: 4px;
        transition: left 0.25s;
    }

    .toggle-checkbox+& {
        background: var(--whiite);
        border: 1px solid var(--mahogany);
    }

    .toggle-checkbox:checked+& {
        border: 1px solid var(--green-apple);

        &:before {
            left: 21px;
            background: var(--green-apple);
        }
    }
}

.toggle-checkbox {
    position: absolute;
    visibility: hidden;
}