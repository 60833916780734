.notification {
    position: relative;

    .notification-dropdown {
        position: absolute;
        width: 400px;
        height: 450px;
        background: var(--white);
        right: -185px;
        top: 50px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        animation: slide-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        transition: all 0.5s;
        z-index: 2;

        &::before {
            position: absolute;
            content: "";
            border-color: transparent;
            border: 15px solid var(--white);
            top: -10px;
            right: 180px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            transform: rotate(135deg);
            z-index: -1;
        }

        .header {
            height: 56px;
            padding: 0 25px;
            border-bottom: 1px solid $very-light-grey;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            cursor: text;

            .title {
                font-weight: $fw-medium;
                @include align-center;
                z-index: 1;

                .count {
                    width: 30px;
                    height: 30px;
                    @include align-justify-center;
                    color: var(--white);
                    @include bg-Gradient(90deg, 0%, 100%);
                    border-radius: 50%;
                    margin-left: 15px;
                }
            }

            .action {
                text-decoration: none;
                color: var(--selective-yellow);
                font-size: $fs-small;
                font-weight: $fw-medium;
                cursor: pointer;
            }
        }

        .body {
            height: 336px;
            overflow: auto;

            .profile {
                border-bottom: 1px solid $very-light-grey;
                margin-left: 0;
                padding: 20px 25px;
                align-items: flex-start;
                justify-content: unset;

                &:last-child {
                    border-bottom: 0;
                }

                .profile-circle {
                    width: 32px;
                    height: 32px;
                }

                .profile-content {
                    display: block;

                    .name {
                        font-weight: $fw-semibold;
                        line-height: 24px;
                        @include align-center-justify-between;

                        .time {
                            font-size: $fs-small;
                            font-weight: $fw-regular;
                            line-height: 24px;
                        }
                    }

                    .subject {
                        font-size: $fs-small;
                        font-weight: $fw-medium;
                        line-height: 18px;
                    }
                }
            }
        }

        .footer {
            height: 58px;
            border-top: 1px solid $very-light-grey;
            cursor: pointer;
            @include align-justify-center;
            font-size: $fs-small;
            font-weight: $fw-medium;
        }
    }
}

.bell {
    animation: ring 4s .7s ease-in-out infinite;
    transform-origin: 50% 4px;
}

@keyframes ring {
    0% {
        transform: rotate(0);
    }

    1% {
        transform: rotate(30deg);
    }

    3% {
        transform: rotate(-28deg);
    }

    5% {
        transform: rotate(34deg);
    }

    7% {
        transform: rotate(-32deg);
    }

    9% {
        transform: rotate(30deg);
    }

    11% {
        transform: rotate(-28deg);
    }

    13% {
        transform: rotate(26deg);
    }

    15% {
        transform: rotate(-24deg);
    }

    17% {
        transform: rotate(22deg);
    }

    19% {
        transform: rotate(-20deg);
    }

    21% {
        transform: rotate(18deg);
    }

    23% {
        transform: rotate(-16deg);
    }

    25% {
        transform: rotate(14deg);
    }

    27% {
        transform: rotate(-12deg);
    }

    29% {
        transform: rotate(10deg);
    }

    31% {
        transform: rotate(-8deg);
    }

    33% {
        transform: rotate(6deg);
    }

    35% {
        transform: rotate(-4deg);
    }

    37% {
        transform: rotate(2deg);
    }

    39% {
        transform: rotate(-1deg);
    }

    41% {
        transform: rotate(1deg);
    }

    43% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(0);
    }
}


@-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
}

@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
}