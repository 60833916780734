.badge-primary {
    @include badge(var(--fern-bg), var(--fern));
}

.badge-secondary {
    @include badge(var(--lightning-yellow-bg), var(--lightning-yellow));
}

.badge-core {
    @include badge(var(--royal-blue-bg), var(--royal-blue));
}

.badge-normal {
    @include badge(var(--badge-grey-bg), var(--badge-grey));
}

.badge-md {
    border-radius: 10px;
    font-size: $fs-small;
    @include align-justify-center;
    width: max-content;
    padding: 5px;
    // background-color: $bg;
    line-height: 24px;
    width: 116px;
    text-transform: capitalize;
    line-height: 17px;
    text-align: center;
}

.profile-badge {
    height: 30px;
    border-radius: 43px;
    @include align-center;
    background-color: var(--very-light-grey);
    width: fit-content;
    padding: 0 12px 0 4px;

    .profile-img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 2px solid var(--white);
        @include align-center;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .name {
        font-size: $fs-small;
        margin-left: 8px;
        color: var(--dim-gray);
    }
}