.main-container {
    display: flex;
    height: calc(100vh - 95px);

    .main-content {
        padding: 40px;
        overflow: auto;
        overflow-x: hidden;
        display: flex;
        flex-grow: 1;

        .page-content {
            width: 100%;
            height: max-content;
        }
    }
}

@media only screen and (max-width:640px) {
    .main-container {
        .main-content {
            padding: 35px;
        }
    }
}

@media only screen and (max-width:480px) {
    .main-container {
        .main-content {
            padding: 25px;
        }
    }
}