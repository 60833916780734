.modal-content {
    padding: 30px;
    border-radius: 10px;

    .modal-header {
        @include align-center-justify-between;
        padding: 0;
        border: 0;

        .title {
            font-size: $fs-h5;
        }

        .close-icon {
            cursor: pointer;
        }
    }

    .modal-body {
        p{
            line-height: 24px;
        }
        padding: 30px 0 0;
    }
}

//Duration dropdowns in manage responsibility modal
.manage-responsibility {
    .modal-body {
        .duration-container {
            .groups {
                .group-item {
                    .dropdown-group {
                        .duration {
                            @include align-center;
                            height: '51px';

                            .time-label {
                                p {
                                    text-align: center;
                                }

                                background-color:var(--labelWhite);
                                padding: 10px;
                                border-radius: 10px 0px 0px 10px;
                            }

                            .drop-down {
                                &.filter-dropdown {
                                    [class*=-control] {
                                        border-radius: 0 10px 10px 0;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}




//add employee model
.add-employee-model {
    .modal-body {
        .groups {
            display: flex;
            gap: 24px;
            margin-bottom: 19px;

            &:last-child {
                margin-bottom: 0;
            }

            .group-item {
                width: 100%;

                .item {
                    margin-bottom: 19px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .model-actions {
            margin-top: 34px;
            @include align-center-justify-end;
            .btn-position {
                margin-right: 15px;
            }

            .primary-btn {
                margin-right: 15px;
            }
        }
    }
}

//assign Emp
.body-content {
    background: $white-smoke;
    border-radius: 15px;

    .table {
        padding: 0 15px 20px 0;
        display: block;
        margin-top: 20px;

        tbody {
            .no-data-msg{
                img{
                    width: 240px;
                }
            }
            display: block;
            height: 185px;
            overflow: auto;
        }

        thead {
            tr {
                display: table;
                width: 100%;
                table-layout: fixed;
                border-bottom: 1px solid $light-grey;

                th {
                    padding: 20px 20px;
                    color: $dim-gray;
                    font-size: $fs-small;
                    font-weight: $fw-medium;


                }
            }
        }

        tbody tr {
            display: table;
            width: 100%;
            table-layout: fixed;

            td {
                padding: 8px 20px;
                color: $dim-gray;
                font-size: $fs-small;
                position: relative;
            }
        }
    }

    .emp-search {
        position: relative;
        padding: 20px 20px 0 20px;

        .search-icon {
            position: absolute;
            top: 32px;
            right: 40px;
        }

        .form-control {
            border: 0;
            border-radius: 8px;

            &::placeholder {
                color: $placeholdertxt;
                font-size: 12px;
            }
        }
    }

    .form-check {
        position: absolute;
        top: 3px;
        right: 0;
        margin-right: 25px;


    }
}

//manage salary modal
.manage-salary-modal {
    .modal-body {
        .profile-section {
            min-height: 101px;
            padding: 19px 27px;
            background-color: var(--snow);
            @include align-center;
            gap: 20px;
            border-radius: 15px;
            .profile-img {
                img {
                    width: 60px;
                }

                width: 63px;
                height: 63px;
                border-radius: 50%;
                font-size: x-large;
                border: 3px solid transparent;
                @include align-justify-center;
                background-color: var(--traffic-white);
                overflow: hidden;
            }

            .profile-details {
                text-transform: capitalize;

                .name {
                    font-size: $fs-big;
                }

                .badges {
                    @include align-center;
                    gap: 31px;
                    margin-top: 6px;

                    .emp-id {
                        @include align-center;
                        gap: 6px;
                    }
                }
            }
        }

        .form-fields {
            padding: 18px 0 28px;
            display: flex;
            flex-direction: column;
            gap: 14px;

            .groups {
                @include align-center;
                gap: 18px;

                .group-item {
                    width: 100%;
                }

            }
        }

        .model-actions {
            display: flex;
            justify-content: end;
            gap: 14px;
        }

        .table-section {
            margin-top: 12px;
            padding-top: 12px;

            .no-data-msg {
                img {
                    width: 200px;
                }
            }
        }
    }
}

// map responsibilities
.map-responisibilities-model {
    .modal-content {
        background-color: transparent;
        padding: 0;
        overflow: hidden;

        .modal-header {
            background-color: var(--white);
            padding: 30px 30px 0;
        }

        .actions {
            background-color: var(--white);
            padding: 22px 30px;
            @include align-center-justify-between;
            gap: 15px;

            .dropdown-group {
                @include align-center-justify-between;
                gap: 15px;
                width: 100%;

                .drop-down {
                    width: 100%;
                }
            }
        }

        .modal-body {
            padding: 0;
            max-height: 60vh;
            overflow: auto;

            .form-inputs {
                background-color: var(--alabaster);
                padding: 30px;
                display: flex;
                gap: 10px;
                flex-direction: column;

                .card-body {
                    @include align-center-justify-between;
                    gap: 35px;
                    cursor: pointer;

                    .badges {
                        display: flex;
                        gap: 6px;
                    }
                    .description-content {
                        line-height: 23px;
                        font-size: $fs-medium;
                    }
                    .description-head{
                        padding-bottom: 7px;
                    }
                    .time-duration {
                        padding: 0 0 8px 0;

                        .time {
                            font-size: $fs-medium;
                            margin-left: 5px;
                            font-weight: $fw-regular;
                        }
                        .hr-min{
                            font-size: 13px;
                        }
                    }
                }
            }

            .form-footer {
                padding: 34px 30px 38px;
                background-color: var(--white);
                @include align-center-justify-end;
                gap: 24px;
            }
        }
    }
}

//Edit EMp
.avatar-image {
    width: 85px;
    height: 85px;
    @include align-justify-center;
    border-radius: 50%;
    margin-right: 20px;
    position: relative;


    img {
        object-fit: fill;
        width: 100%;
    }

    .img-overlay {
        width: 30px;
        height: 30px;
        background: var(--imgOverlay);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        cursor: pointer;

        img {
            width: 15px;
            height: 15px;
        }
    }

    .pro-pic {
        width: 85px;
        height: 85px;
        overflow: hidden;
        border-radius: 50%;
        @include align-justify-center;
    }
}

.not-found {
    text-align: center;
    min-height: 165px;
    @include align-justify-center;
    align-items: center;
}

.add-role-model {
    .modal-content {
        padding: 0;
        
        .modal-header {
            padding: 30px 30px 0 ;
        }
        
        .modal-body {
            height: 80vh;
            overflow: auto;
            @include scroll;
            padding: 30px;
        }

        input[type="checkbox"] {
            accent-color: var(--selective-yellow);
            line-height: normal;
            width: 15px;
            height: 15px;
        }

        .previleges {
            .heading {
                @include align-center-justify-between;
                margin-bottom: 15px;

                h2 {
                    font-weight: $fw-semibold;
                }
            }

            .content {
                .list {
                    @include align-center-justify-between;

                    .title {
                        font-weight: $fw-medium;
                        cursor: pointer;
                    }

                    .inputs {
                        width: 25px;
                    }
                }

                .sub-list {
                    margin-left: 20px;
                }
            }
        }
    }
}

.add-classification-model {

    .modal-content {
        .groups {
            .group-item {
                .color-input{
                    width: 55px;
                    height: 23px;
                    border: 2px solid var(--white);
                    outline: 2px solid var(--black);
                }
                .custom-sketch-picker {
                    width: 100px;
                }
            }
        }

        .colorpicker-container {
            position: relative;
            display: flex;
            justify-content: baseline;
            gap: 80px;
            .colorpicker-design{
                position: absolute;
                z-index: 1;
                bottom: 50px;
            }
        }
    }

}

@media only screen and (max-width: 991px) {
    .add-employee-model {
        .modal-body {
            .groups {
                display: block;

                .group-item {
                    margin-bottom: 19px;
                }
            }

            .model-actions {
                justify-content: center;
            }
        }
    }

    .assign-employee-modal {
        .table-wrapper {
            overflow: auto;
            .table {
                min-width: 600px;
            }
        }
    }
}