.header {
    height: 95px;
    background-color: var(--white);
    padding-right: 50px;
    padding-left: 31px;
    @include align-center-justify-between;

    .logo-part {
        @include align-center;
        width: 270px;
        transition: all 0.25s;

        &.isShort {
            width: 120px;
            transition: all 0.25s;
        }

        .logo {
            .for-small-screen {
                display: none;
            }
        }
    }

    .actions {
        @include align-center;

        .mode-change {
            cursor: pointer;
            margin-left: 15px;
            padding-left: 10px;

            &.dark {
                filter: invert(1);
            }
        }

        .profile {
            margin-left: 15px;
            padding-left: 10px;
        }
    }

    .logo-part {
        .hamburger-menu {
            cursor: pointer;
            width: 35px;
            @include align-center-justify-between;
            display: none;
            margin-right: 15px;

            img {
                width: 100%;
            }
        }
    }
}

.profile {
    @include align-justify-center;
    position: relative;
    cursor: pointer;

    .profile-circle {
        @include align-justify-center;
        width: 38px;
        height: 38px;
        background-color: var(--grey-bg);
        border-radius: 50%;
        overflow: hidden;

        span {
            font-weight: $fw-semibold;
            text-transform: capitalize;
            font-size: $fs-large;
        }
    }

    .profile-content {
        margin-left: 11px;
        width: 100%;
        @include align-center;

        .name {
            font-weight: $fw-medium;
            text-transform: capitalize;
            line-height: 25px;
        }

        .role {
            font-size: $fs-micro;
            text-transform: capitalize;
        }

        .opened {
            transform: rotate(180deg);
        }

        .arrow {
            transition: all 0.3s;
            width: 18px;

            img {
                width: 100%;
            }
        }
    }

    .dropdown {
        position: absolute;
        width: 180px;
        background: var(--white);
        border-radius: 10px;
        box-shadow: 0px 2px 11px -2px rgba(0, 0, 0, 0.1);
        animation: slide-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        transition: all 0.5s;
        z-index: 2;
        padding: 20px 25px;
        top: 50px;
        right: -16px;

        &::before {
            position: absolute;
            content: "";
            border-color: transparent;
            border: 15px solid var(--white);
            top: -10px;
            right: 11%;
            box-shadow: -2px 2px 5px -3px rgba(0, 0, 0, 0.1);
            transform: rotate(135deg);
        }

        .item-wrapper {
            .item {
                @include align-center;
                margin-bottom: 20px;
                cursor: pointer;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
}

@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
}

@media only screen and (max-width:768px) {
    .header {
        .logo-part {
            .hamburger-menu {
                display: block;
            }
        }

        .profile {
            .profile-content {
                display: none;
            }
        }
    }
}

@media only screen and (max-width:575px) {
    .header {
        padding-right: 20px;
        padding-left: 10px;

        .logo-part {
            .logo {
                .for-big-screen {
                    display: none;
                }

                .for-small-screen {
                    display: block;
                }
            }
        }

        .actions {
            .profile {
                margin-left: 13px;
                padding-left: 12px;
            }
        }
    }
}