.drop-down {
    [class*="-indicatorSeparator"] {
        display: none;
    }

    [class*="-indicatorContainer"] {
        color: var(--eclipse);
    }

    [class*="-is-selected"] {
        @include bg-Gradient(3deg, 8.24%, 89.67%);
        color: var(--white);
    }

    [class*="-menu"] {
        border-radius: 10px;
        border: 0;
        box-shadow: 0 0 0 0px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);

        [class*="-option"] {
            cursor: pointer;
            padding: 12px 15px;
            text-transform: capitalize;
            font-size: $fs-small;

            &:hover {
                background-color: var(--lightning-yellow-bg) !important;
            }
        }

        [class*="-is-focused"] {
            background-color: var(--lightning-yellow-bg) !important;
        }

        [class*="-MenuList"] {
            padding-top: 10px;
            padding-bottom: 10px;
            @include scroll;
        }
    }

    [class*="-control"] {
        font-size: 14px;
        border-radius: 10px;
        border: none;
        white-space: nowrap;
        text-overflow: clip;
        width: 100%;
        cursor: pointer;
        box-shadow: none;
        padding: 5px 10px;
        background: var(--snow);
        font-weight: $fw-medium;
        height: 50px;

        &.error-input {
            background: var(--error-bg);
            border: 1px solid var(--mahogany);
        }
    }

    [class*="-singleValue"] {
        text-transform: capitalize;
        color: var(--eclipse);
        font-size: $fs-normal;
    }

    [class*="placeholder"] {
        font-size: $fs-normal;
        color: var(--eclipse);
        font-weight: $fw-medium;
    }

    &.filter-dropdown {
        [class*="-control"] {
            height: 38px;
            padding: 0 7px;
        }

        [class*="-singleValue"] {
            font-size: $fs-small;
            line-height: 20px;
        }

        [class*="placeholder"] {
            font-size: $fs-small;
        }
    }
}