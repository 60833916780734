@media print {
    .reportees {
        .userDetail {
            display: flex;
            align-items: center;
            column-gap: 15px;

            img {
                width: 38px;
                height: 38px;
                border-radius: 50%;
            }
        }

        .custom-table table thead tr th {
            min-width: unset;
        }

        .profile-circle-md {
            width: 38px;
            height: 38px;
            font-size: 0.938rem;
            font-weight: $fw-medium;

        }

        .reportees-view-btn {
            height: 32px;
            min-width: 90px;
        }
    }
}

@page {
    margin: 25px;
}