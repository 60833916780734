.rmdp-container {
    width: 100%;

    .date-input {
        height: 50px;
        padding: 0 60px 0 15px;
        border-radius: 12px;
        background-color: var(--snow);
        border-color: transparent;
        width: 100%;
    }

    .rmdp-ep-arrow {
        border: unset;
        width: 20px !important;
    }

    .rmdp-wrapper {
        .rmdp-calendar {
            .rmdp-header {
                .rmdp-arrow-container {
                    &.disabled {
                        opacity: 0.2;
                    }

                    &:hover {
                        background-color: transparent;
                        box-shadow: none;
                    }

                    .rmdp-arrow {
                        border-color: $black;
                        height: 8px;
                        width: 8px;
                    }
                }
            }

            .rmdp-day-picker {
                .rmdp-week {
                    .rmdp-week-day {
                        color: var(--selective-yellow);
                        font-size: 10px;
                    }
                }
            }

            .rmdp-day-picker,
            .rmdp-year-picker,
            .rmdp-month-picker {

                .rmdp-ym,
                .rmdp-week {
                    .rmdp-day {
                        &.rmdp-selected {
                            span:not(.highlight) {
                                background: var(--selective-yellow);
                                box-shadow: none;
                                color: var(--white);
                            }
                        }

                        &.rmdp-today {
                            span {
                                background: transparent;
                                color: var(--selective-yellow);
                                font-weight: $fw-semibold;
                            }
                        }

                        &:not(.rmdp-disabled, .rmdp-day-hidden) {
                            span {
                                &:hover {
                                    background: var(--selective-yellow-dull);
                                    color: var(--white);
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}

.date-picker-wrapper {
    position: relative;

    .calendar-icon {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 15px;
    }
}

.date-notification {
    position: relative;

    .custom-date-picker {
        .date-picker-wrapper {
            position: relative;

            .date-input {
                height: 50px;
                padding: 0 60px 0 15px;
                border-radius: 12px;
                background-color: var(--snow);
                border-color: transparent;
                width: 100%
            }

            .calendar-icon {
                cursor: pointer;
                position: absolute;
                right: 20px;
                top: 15px;
            }
        }
    }

    .date-notification-dropdown {
        position: absolute;
        width: 256px;
        height: 299px;
        background: var(--white);
        right: 25px;
        top: 65px;
        border-radius: 5px;
        box-shadow: 0 0 5px $date-shadow;
        transition: all 0.5s;
        z-index: 2;

        &:before {
            position: absolute;
            content: "";
            border-color: transparent;
            border: 15px solid var(--white);
            top: -2px;
            right: 116px;
            transform: rotate(135deg);
            z-index: -1;
        }

        .date-header {
            margin: 15px;
            font-size: $fs-normal;
        }

        .body {
            overflow: auto;
            height: 220px; 

            .date-details {
                font-size: $fs-micro;
                padding: 7px;
                margin: 10px;
                background: $dark-gray-dull;
                border-radius: 5px;
                text-align: center;

                &:hover {
                    background-color:  var(--selective-yellow-dull);
                    color: $white;
                }

                &.active {
                    background-color: var(--selective-yellow);
                    color: $white;
                }
            }
        }
    }
}