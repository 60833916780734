.page-loader-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    backdrop-filter: blur(3px);
    border-radius: 10px;

    .page-loader {
        width: 50px;
        aspect-ratio: 1;
        border-radius: 50%;
        background:
            radial-gradient(farthest-side, #ffa516 94%, #0000) top/8px 8px no-repeat,
            conic-gradient(#0000 30%, #ffa516);
        -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
        animation: loader 1s infinite linear;
    }


}

.dropdown-loader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 1;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 6%;
    backdrop-filter: blur(3px);

    .page-loader {
        width: 25px;
        aspect-ratio: 1;
        border-radius: 50%;
        background:
            radial-gradient(farthest-side, #ffa516 94%, #0000) top/8px 8px no-repeat,
            conic-gradient(#0000 30%, #ffa516);
        -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
        animation: loader 1s infinite linear;
    }
}
.loader-card {
    min-height: 300px
 }

@keyframes loader {
    100% {
        transform: rotate(1turn)
    }
}