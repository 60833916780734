.mapping-list {
    .screen-actions {
        @include align-center;
        gap: 20px;

        .tab-group {
            width: 100%;
        }
    }

    //employees tab
    .employee-main {
        .employee-loader-position{
            position: relative;
        }
        .actions-group {
            @include align-center-justify-between;

            .dropdown-group {
                display: flex;
                gap: 17px;

                .drop-down {
                    width: 198px;
                }
            }
        }

        //responsibility sub component  
        .responsibility-sub-component {
            table {
                thead {
                    th {
                        &:last-child {
                            width: 300px;
                        }
                    }
                }

                tbody {
                    tr {
                        .profile-badge-wrapper {
                            @include align-center;
                            gap: 13px;
                            flex-wrap: wrap;

                            .plus {
                                height: 30px;
                                width: 30px;
                                border-radius: 50%;
                                @include align-justify-center;
                                background-color: var(--very-light-grey);
                                font-size: $fs-large;
                            }

                            .plus-count {
                                @include text-Gradient(100%, 100%)
                            }
                        }

                        &.sub-row {
                            color: var(--dim-grey);

                            .description {
                                font-size: $fs-small;
                                line-height: 21px;
                            }

                            h2 {
                                margin: 8px 0 8px;
                                font-weight: $fw-medium;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.reponssibility-grp {
    display: grid;
    grid-template-columns: 20% 20% 60%;
    height: 100%;

    .departmentinfo-card{
        margin-right: 20px;
    }

    .mapping-grp {
        padding-bottom: 25px;
        max-height: 500px;
        overflow-y: auto;
    }

    .card {
        margin-right: 20px;

        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background: var(--overlay);
            border-radius: 10px;
            z-index: 1;
            backdrop-filter: blur(3px);
        }
    }

    .selected-counts {
        margin-top: 31px;
    }

}
.submit-actions {
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: end;
    gap: 29px;
}

.mp-head {
    padding: 20px;

    h5 {
        font-size: $fs-normal;
        font-weight: $fw-medium;
        color: $eclipse;
    }

    .filter-search {
        max-width: 250px;
    }
}

.mapping-grp {

    .mapping-wrapper {
        padding: 10px 25px 0 25px;
        
        .mapping-item { 
            padding: 0;
            padding-bottom: 10px;
            border-bottom: 1px solid $mappinghover;
            
        }
        
        &:last-child {
            .mapping-item { 
                border-color: transparent;
            }
        }
        
        &:hover {
            background: $mappinghover;
        }
    }
}

.mapping-item {
    padding: 10px 25px;
    cursor: pointer;
    @include align-center-justify-between;

    &:hover {
        background: $mappinghover;
    }

    p {
        &::first-letter {
            text-transform: capitalize;
        }
    }
}

.map-label {
    width: 92%;
    .description{
        text-align: justify;
    }
    .description-head{
        padding-bottom: 7px;
    }
    .description-content{
        line-height: 23px;
        font-size: $fs-medium;
    }
    .time-duration {
        padding: 8px 0;

        .time {
            font-weight: $fw-regular;
            margin-left: 5px;
            font-size: $fs-medium;
        }
        .hr-min{
            font-size: 13px;
        }
    }

}

@media only screen and (max-width: 1600px) {
    .reponssibility-grp {
        grid-template-columns: 25% 25% 50%;
    }
}

@media only screen and (max-width: 1080px) {
    .mapping-list {
        .employee-main {
            .actions-group {
                flex-direction: column;
                align-items: flex-start;

                .toggle-button-wrapper {
                    margin-top: 16px;
                }
            }
        }
    }

    .reponssibility-grp {
        display: block;

        .card {
            margin-right: 0;
            margin-bottom: 25px;
        }

        .selected-counts {
            margin-top: 15px;
            margin-bottom: 30px;
        }

        .submit-actions {
            margin-top: 20px;
            padding-top: 30px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .res-mapping {
        .mapping-item {
            width: 650px;
        }
    }
}

@media only screen and (max-width: 480px) {
    .mapping-list {
        .screen-actions {
            flex-direction: column;
            gap: 0;
            align-items: flex-start;
            margin-bottom: 22px;

            .tab-group {
                overflow: auto;
                overflow-y: hidden;
                margin-bottom: 20px;
            }
        }

        .employee-main {
            .actions-group {
                .dropdown-group {
                    .drop-down {
                        &.filter-dropdown {
                            width: 127px;

                            [class*=-control] {
                                padding: 0 2px;
                            }
                        }
                    }
                }
            }
        }
    }
}