.custom-tooltip {

    .tooltip {
        &.show {
            opacity: 1;
        }

        .tooltip-inner {
            @include bg-Gradient(3deg, 8.24%, 89.67%);
            margin-left: 20px;
        }

        .arrow {
            &.tooltip-arrow {
                &::before {
                    border-right-color: var(--selective-yellow);
                    left: 20px;
                }
            }
        }
    }
}