.page-loader-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    backdrop-filter: blur(1px);
    border-radius: 10px;

    .page-loader {
        width: 50px;
        aspect-ratio: 1;
        border-radius: 50%;
        background:
            radial-gradient(farthest-side, #ffa516 94%, #0000) top/8px 8px no-repeat,
            conic-gradient(#0000 30%, #ffa516);
        -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
        animation: loader 1s infinite linear;
    }
}

@keyframes loader {
    100% {
        transform: rotate(1turn)
    }
}