.cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 
    grid-gap: 20px;
}

.card {
    border: 0;
    border-radius: 10px;

    .card-body {
        padding: 20px;
    }
}

// user-card 
.user-card {
    .card-body {
        position: relative;
        display: flex;
        height: 160px;

        .profile-img {
            img{
                width: 40px;
            }
            width: 50px;
            height: 50px;
            border: 3px solid transparent;
            border-radius: 50%;
            overflow: hidden;

            @include align-justify-center;
            margin-right: 18px;

            &.active {
                outline: 2px solid var(--fern);
            }

            &.inactive {
                outline: 2px solid red;
            }

            span {
                font-weight: $fw-semibold;
                font-size: $fs-extra-big;
            }
        }

        .profile-details {
            overflow: hidden;

            .name {
                font-size: $fs-big;
                font-weight: $fw-medium;
                line-height: 24px;
            }

            .email {
                font-size: $fs-small;
                line-height: 18px;
                // width: 90%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }

            .badges {
                display: flex;
                margin: 7px 0 14px 0;

                .designation {
                    margin-right: 11px
                }

                .counts {
                    @include align-center-justify-between;
                    height: 27px;
                    border: 1px solid var(--very-light-grey);
                    // width: 60px;
                    width: auto;
                    border-radius: 53px;
                    padding: 0 6px;
                    font-size: $fs-small;

                    &.responsibility-approved {
                        height: 18px;
                    }
                }
            }

            .report-to {
                @include align-center;
                font-size: $fs-micro;
                margin-top: 8px;
                color: var(--nobel);

                .img {
                    @include align-justify-center;
                    width: 20px;
                    height: 20px;
                    background-color: var(--white-smoke);
                    border-radius: 50%;
                    margin-right: 5px;
                }
            }

        }

        .menu {
            position: absolute;
            right: 20px;
            top: 15px;
            cursor: pointer;
            background: var(--bg);
            padding: 5px 5px;
            border-radius: 5px;

            .btn-group {
                width: 100%;
            }

            button {
                img {
                    width: 3px;
                }
            }

            .dropdown-menu {
                width: 210px;

                .dropdown-item {
                        padding: 15px 20px;
                
                        &:hover {
                            background-color: var(--selective-yellow);
                            color: var(--white);
                        }
                
                        &:first-child:hover {
                            border-top-left-radius: 10px;
                            border-top-right-radius: 10px;
                        }
                
                        &:last-child:hover {
                            border-bottom-left-radius: 10px;
                            border-bottom-right-radius: 10px;
                        }


                    &:not(:last-child) {
                        border-bottom: 1px solid var(--grey-94);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:375px) {

    .cards {
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr)); 
    }

    // user-card 
    .user-card {
        .card-body {
            .profile-img {
                width: 30px;
                height: 30px;
                margin-right: 10px;

                span {
                    font-size: $fs-big;
                }
            }

            .profile-details {
                .name {
                    font-size: $fs-large;
                }
            }
        }
    }
}