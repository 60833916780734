body {
    --eclipse:#{$eclipse};
    --bg: #{$bg};
    --white-smoke:#{$white-smoke};
    --white:#{$white};
    --grey-bg:#{$grey-bg};
    --night-rider-bg:#{$night-rider-bg};
    --sun-bg:#{$sun-bg};
    --nobel:#{$nobel};
    --dove-gray:#{$dove-gray};
    --dim-gray:#{$dim-gray};
    --night-rider:#{$night-rider};
    --royal-blue-bg:#{$royal-blue-bg};
    --fern-bg:#{$fern-bg};
    --royal-blue:#{$royal-blue};
    --selective-yellow:#{$selective-yellow};
    --selective-yellow-2:#{$selective-yellow-2};
    --safron:#{$safron};
    --sun:#{$sun};
    --fern:#{$fern};
    --submenu-bg:#{$submenu-bg};
    --very-light-grey:#{$very-light-grey};
    --lightning-yellow:#{$lightning-yellow};
    --lightning-yellow-bg:#{$lightning-yellow-bg};
    --snow:#{$snow};
    --geraldine:#{$geraldine};
    --early-dawn:#{$early-dawn};
    --silver:#{$silver};
    --grey:#{$grey};
    --dark-gray:#{$dark-gray};
    --error-bg:#{$error-bg};
    --black-disabled:#{$black-disabled};
    --light-black:#{$light-black};
    --green-apple:#{$green-apple};
    --mahogany:#{$mahogany};
    --matterhorn:#{$matterhorn};
    --light-grey:#{$light-grey};
    --gainsboro:#{$gainsboro};
    --traffic-white:#{$traffic-white};
    --blue:#{$blue};
    --badge-grey-bg:#{$badge-grey-bg};
    --badge-grey:#{$badge-grey};
    --grey-94:#{$grey-94};
    --alabaster:#{$alabaster};
    --shadow:#{$shadow};
    --labelBlack:#{$labelBlack};
    --labelWhite:#{$labelWhite};
    --overlay:#{$overlay};
    --imgOverlay:#{$imgOverlay};
    --toggleBackground:#{$toggleBackground};
    --selective-yellow-dull:#{$selective-yellow-dull};
    --black:#{$black};
}