.rejectReasion-model-header {
  @include align-center-justify-between;
  .title {
    font-size: $fs-big;
    font-weight: $fw-medium;
    color: var(--eclipse);
  }
  .close-icon {
    cursor: pointer;
  }
}
.rejectReasion-model-body {
  margin-top: 29px;
  .reject-title {
    font-size: $fs-normal;
    font-weight: $fw-regular;
    color: var(--dove-gray);
    margin-bottom: 11px;
  }
  .rejectReason {
    border: none;
    background-color: var(--snow);
    width: 100%;
    min-height: 100px;
    outline: none;
    padding: 15px;
    resize: none;
    & .text-area{
      resize: none
    }
  }
  .rejectReasion-model-footer {
    @include align-center;
    margin-top: 29px;
    gap: 20px;
  }
}
