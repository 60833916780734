.organiscope {
    .card {
        padding: 25px;
        height: 100%;

        .title {
            color: var(--dim-gray);
            font-size: $fs-h5;
            font-weight: $fw-medium;
            margin-bottom: 12px;

            .edit-btn {
                border: 1px solid;
                background: transparent;
                color: var(--selective-yellow);
                font-size: $fs-small;
                font-weight: $fw-regular ;
                padding: 12px 18px;
                border-radius: 30px;

                &:before {
                    content: url(../../images/svgIcons/edit.svg);
                    margin-right: 7px;
                    position: relative;
                    top: 1px;
                }
            }
        }

        .alert-box {
            background: $snow;
            padding: 30px;
            color: $dim-gray;
            font-size: $fs-normal;
            font-weight: $fw-regular;
            border-radius: 10px;
            line-height: 24px;
            min-height: 130px;

            .text-area {
                background: transparent;
                color: $dim-gray;
                font-size: $fs-normal;
                width: 100%;
                min-height: 60px;
                overflow: auto;
                border: none;
                outline: none;
                resize: none;
                padding: unset;
            }

            .scope-description {
                min-height: 60px;
            }
        }

        .edit-btn-1 {
            border: 1px solid;
            background: transparent;
            color: var(--selective-yellow);
            font-size: $fs-small;
            font-weight: $fw-regular ;
            padding: 12px 18px;
            border-radius: 30px;
        }
    }
}

.d-scope {
    .title {
        font-size: $fs-large;
        font-weight: $fw-medium;
        color: var(--dim-gray);
        line-height: 27px;
    }

    .description {
        font-size: $fs-small;
        font-weight: $fw-regular;
        color: var(--dim-gray);
        line-height: 21px;
    }
}
.department-scope{
    .cell-data{
        padding: 7px 10px !important;
    }
}

.scope {
    .custom-table {
        table {
            tbody {
                tr {
                    td {
                        .cell-data {
                            padding: 9px 10px;
                        }
                    }
                }

            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .organiscope {
        .card {
            .title {
                font-size: $fs-normal;
            }

            .alert-box {
                font-size: $fs-small;
                padding: 25px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .organiscope {
        .card {
            .title {
                font-size: $fs-normal;

                .edit-btn {
                    margin-top: 10px;
                }
            }

            .alert-box {
               .scope-description {
                line-height: 18px;
               }
            }
        }
    }
}

@media only screen and (max-width: 680px) {
    .scope {
        .tabs {
            overflow: auto;
            overflow-y: hidden;

            .tab-group {
                margin-bottom: 0;

                .tab-item {
                    span {
                        &::after {
                            bottom: -6px;
                            height: 2px;
                        }
                    }
                }
            }
        }
    }
}