$fontFamily: 'Poppins', sans-serif;

//header
$fs-h1: 1.75rem; // 28px
$fs-h2: 1.625rem; // 26px
$fs-h3: 1.5rem; // 24px
$fs-h4: 1.375rem; // 22px
$fs-h5: 1.25rem; // 20px
$fs-h6: 1.125rem; // 18px

//font size
$fs-XXXL: 3.125rem; // 50px
$fs-XXL: 2rem; // 32px
$fs-extra-big: 1.875rem; // 30px
$fs-h4-big: 1.438rem; // 23px
$fs-big: $fs-h5; // 20px
$fs-large: $fs-h6; // 18px
$fs-normal: 1rem; // 16px
$fs-medium: 0.938rem; // 15px
$fs-small: 0.875rem; // 14px
$fs-mini: 0.813rem; // 13px
$fs-micro: 0.75rem; // 12px

//font weight
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-bolder: 800;
$fw-extrabold: 900;