.pagination-wrapper {
  @include align-center-justify-between;
  height: 100%;
  padding: 10px 12px 6px 32px;
  .pagination-container {
    display: flex;
    list-style-type: none;

    .pagination-item {
      padding: 0 12px;
      height: 32px;
      text-align: center;
      margin: auto 4px;
      box-sizing: border-box;
      border-radius: 5px;
      line-height: 1.43;
      font-size: 14px;
      min-width: 32px;
      @include align-justify-center;

      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }

      &:hover {
        background-color: var(--lightning-yellow-bg);
        cursor: pointer;
      }

      &.selected {
        background-color: var(--selective-yellow);
        color: var(--white);
      }

      .arrow {
        @include align-justify-center;

        img {
          width: 70%;
        }

        &.left {
          transform: rotate(90deg);
        }

        &.right {
          transform: rotate(270deg);
        }
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.3;

        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }
}

@media only screen and (max-width:768px) {
  .pagination-wrapper {
    flex-direction: column;
    align-items: flex-start;

    .page-count {
      margin-top: 20px;

      .pagination-container {
        .pagination-item {
          margin: 0;
          padding: 0;
          height: 25px;
          min-width: 25px;
        }
      }
    }
  }
}