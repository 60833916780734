.sidebar-wrapper {
    position: relative;

    .toggle {
        .toggle-menu {
            width: 40px;
            height: 40px;
            padding: 6px;
            border-radius: 50%;
            @include align-justify-center;
            position: absolute;
            right: -45px;
            top: -65px;
            cursor: pointer;

            img {
                transition: all 0.3s;
                width: 100%;
            }

        }
    }

    .sidebar {
        background-color: var(--white);
        height: 100%;
        z-index: 1;

        .menu-items {
            width: 300px;
            padding: 60px 38px 30px;
            overflow: auto;
            overflow-x: hidden;
            transition: all 0.25s;

            li {
                &:hover {
                    background-color: var(--submenu-bg);
                    border-radius: 10px;
                }

                &.submenu-list {
                    cursor: pointer;

                    &.active-list {
                        background-color: var(--submenu-bg);
                        border-radius: 10px;
                    }

                    li {
                        margin-bottom: 0
                    }

                    .dropdown {
                        display: none;
                    }
                }

                .item {
                    @include align-center;
                    text-decoration: none;
                    padding: 18px 21px;
                    color: var(--dim-gray);
                    height: 60px;

                    &.with-submenu {
                        justify-content: space-between;

                        .label-section {
                            @include align-center;
                        }

                        .arrow {
                            transition: all 0.3s;
                            width: 18px;

                            img {
                                width: 100%;
                            }
                        }

                        &.active {
                            .arrow {
                                filter: invert(1);
                            }

                            &.open {
                                +.dropdown {
                                    display: block;
                                }

                                .arrow {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }

                    .icon {
                        margin-right: 16px;
                    }

                    &.active {
                        background-color: var(--selective-yellow);
                        border-radius: 10px;
                        color: var(--white);
                    }
                }

                .sub-item {
                    &.active {
                        background-color: transparent;
                        color: var(--selective-yellow);
                    }
                }
            }
        }

        &.short-menu {
            .menu-items {
                width: 100px;
                transition: all 0.25s;
                padding: 60px 0 20px;
                @include align-justify-center;
                flex-direction: column;

                li {
                    width: 60px;

                    .item {
                        padding: 18px 0;
                        @include justify-center;

                        .icon {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .sidebar-wrapper {
        .sidebar {
            position: absolute;
            left: -500px;
            transition: all 0.25s;

            &.opened {
                left: 0;
            }

            .menu-items {
                padding-top: 30px;
            }
        }

        .toggle {
            .toggle-menu {
                display: none;
            }
        }
    }

}

@media only screen and (max-width:575px) {
    .sidebar-wrapper {
        .sidebar {
            .menu-items {
                padding: 30px;

                li {
                    .item {
                        height: 50px;
                    }
                }
            }
        }
    }
}