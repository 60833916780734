html {
    font-size: 100%;
}

body {
    font-family: $fontFamily;
    background-color: var(--bg);
    font-size: $fs-normal;
    color: var(--eclipse);
}

.cursor-pointer {
    cursor: pointer;
}

//custom scroll
.custom-scroll {
    @include scroll;
}

//Remove Arrows/Spinners for number input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

//page-title
h1 {
    &.page-title {
        font-size: $fs-h1;
    }
}

//tabs 
.tab-group {
    display: flex;
    border-bottom: 1px solid var(--light-grey);
    margin-bottom: 25px;

    .tab-item {
        padding: 22px 20px;
        font-size: $fs-large;
        font-weight: $fw-medium;
        text-wrap: nowrap;

        &:first-child {
            padding-left: 0;
        }

        span {
            cursor: pointer;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 3px;
                border-radius: 28px;
                bottom: -21px;
                left: 0;
            }
        }

        &.active {
            color: var(--selective-yellow);

            span {
                &::after {
                    background-color: var(--selective-yellow);
                }
            }
        }
    }
}

.modal {
    backdrop-filter: blur(5px);
}

.no-data-msg {
    @include align-justify-center;

    img {
        width: 300px;
        max-width: 100%;
    }

}