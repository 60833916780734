.tabSec {
  @include align-center-justify-between;
  margin-bottom: 10px;

  .tabs {
    width: 100%;
  }

  .downAndPrint {
    display: flex;
    flex-direction: row;
    column-gap: 9px;
    margin-left: 10px;
    align-items: flex-end;

    .options {
      @include align-justify-center;
      background-color: var(--white);
      padding: 8px;
      border-radius: 10px;
      width: 36px;
      height: 36px;
      cursor: pointer;

      &.optionsDisable {
        opacity: 0.5;
        cursor: default;

      }
    }
  }
}

.overView {
  .requiredFields {
    @include row-align-justify-center;
    column-gap: 17px;
    margin-bottom: 10px;

    p {
      font-weight: $fw-regular;
      font-size: $fs-mini;
      color: var(--mahogany);
    }
  }



  .dropdowns {
    @include align-center;
    column-gap: 17px;
    margin-bottom: 23px;

    .filter-dropdown {
      width: 180px;
    }
  }

  .myResponsibility {
    .days {
      @include align-center-justify-between;
      flex-wrap: wrap;
      margin-bottom: 23px;

      .calender {
        @include align-center-justify-between;
        padding: 7px;
        min-width: 155px;
        border-radius: 10px;
        background-color: var(--white);

        .date {
          font-weight: $fw-regular;
          font-size: $fs-small;
        }

        .calenderwave {
          background-color: $early-dawn;
          border-radius: 50%;
          align-items: center;
          height: 21px;
          width: 21px;
        }

        .calenderIcon {
          padding: 6px;
        }
      }
    }
  }

  .custom-btn .primary-btn {
    background-color: var(--white);
    color: var(--selective-yellow);
  }

  .userDetail {
    @include align-center;
    column-gap: 15px;

    .profile-circle-md {
      width: 38px;
      height: 38px;
      font-size: $fs-medium;
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 50%;
      font-weight: $fw-medium;
      background-color: var(--traffic-white);
  }

    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }

    .userName {
      font-weight: $fw-regular;
      margin-bottom: 3px;
      text-transform: capitalize;
    }

    .userRole {
      font-weight: $fw-regular;
      font-size: $fs-micro;
      text-transform: capitalize;
    }
  }

  .userDetails {
    .goBack {
      @include align-center;
      column-gap: 7px;
      cursor: pointer;

      img {
        transform: rotate(90deg);
        height: 20px;
        width: 20px;
      }

      p {
        font-size: $fs-small;
        font-weight: $fw-regular;
      }
    }

    .userInfo {
      .userDetail{
        .profile-circle-md {
          width: 47px;
          height: 47px;
          font-size:$fs-h4-big; 
          font-weight:$fw-semibold;    
        }
      }
      @include align-center-justify-between;
      background-color: var(--white);
      min-height: 87px;
      border-radius: 15px;
      margin: 35px 0 35px 0;
      padding-inline: 30px;

      .userImg {
        width: 47px;
        height: 47px;
        border-radius: 50%;
      }

      .cell {
        @include align-center;
        column-gap: 16px;
      }

      .userName {
        font-weight: $fw-regular;
        font-size: $fs-small;
        color: var(--labelBlack);
        text-transform: capitalize;
      }

      .userRole {
        background-color: var(--night-rider-bg);
        border-radius: 15px;
        margin-top: 7px;
        padding: 6px 10px;
        font-size: $fs-micro;
        text-align: center
      }

      .icon-wrapper {
        @include align-justify-center;
        background-color: var(--early-dawn);
        padding: 10px;
        border-radius: 50%;
        height: 34px;
        width: 34px;
      }

      .infoIcon {
        height: 20px;
        width: 20px;
      }

      .label {
        font-weight: $fw-regular;
        font-size: $fs-micro;
        color: var(--dove-gray);
      }

      .labelValue {
        font-weight: $fw-regular;
        font-size: $fs-normal;
        color: var(--labelBlack);
        margin-top: 7px;
      }
    }
  }
}

.reportee-filters {
  @include align-center-justify-between;
  padding-bottom: 25px;

  .table-search {
    .filter-dropdown {
      background: var(--white);
      padding: 10px;
      border-radius: 8px;
      height: 37px;
    }
  }
}

.reportees-view-btn {
  height: 32px;
  min-width: 90px;
}
.center-item {
  @include align-justify-center;
}

@media only screen and (max-width: 890px) {
  .overView {
    .tabSec {
      .tabs {
        overflow: auto;
        overflow-y: hidden;

        .tab-group {
          margin-bottom: 0;
          border-color: transparent;

          .tab-item {
            text-wrap: nowrap;

            span {
              &::after {
                bottom: -6px;
                height: 2px;
              }
            }
          }
        }
      }
    }

    .reportee-filters {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
      padding-bottom: 10px;
      margin-bottom: 10px;

      .dropdowns {
        width: 100%;

        .filter-dropdown {
          width: 100%;
        }
      }
    }

    .days {
      width: 100%;
      overflow: auto;
      overflow-y: hidden;
    }

    .toggle-button-wrapper {
      button {
        width: max-content;
      }
    }
  }
}

@media only screen and (max-width:768px) {
  .overView {
    .userDetails {
      .userInfo {
        flex-direction: column;
        align-items: flex-start;
        padding: 25px;
        grid-gap: 20px;
        margin: 25px 0 25px 0;
        min-height: auto;
      }
    }
  }

  .reportee-filters {
    flex-direction: column;
    align-items: start;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 414px) {
  .overView {
    .dropdowns {
      flex-direction: column;
      gap: 15px;
    }

    .table-search {
      width: 100%;

      .search {
        .filter-search {
          width: 100%;
        }
      }
    }
  }
}