.dropdownSelect {
  .customdropdown {
    background-color: var(--white);
    border: none;
    width: 100%;
    padding: 0;
    &.changeview {
      background-color: transparent !important;
    }
    .tips {
      position: relative;
      &:hover {
        .tooltiptext {
          visibility: visible;
        }
      }
    }

    p {
      color: $black;
      text-transform: capitalize;
    }

    .tooltiptext {
      visibility: hidden;
      position: absolute;
      color: var(--dim-gray);
      font-size: $fs-small;
      background-color: var(--white);
      padding: 10px;
      border-radius: 10px;
      bottom: 30%;
      right: 0;
      position: absolute;
      box-shadow: var(--shadow) 0px 4px 12px;
      z-index: 5;
      width: 176px;
    }
  }

  .options {
    p {
      color: $black;
      text-transform: capitalize;
      font-size: $fs-small;
    }
    @include align-center;
    justify-content: flex-start;
    column-gap: 12px;
    row-gap: 8px;
    border-radius: 10px;
    padding: 4px;
    &:hover {
      background-color: var(--$white-smoke);
    }
    .selectVal {
      color: var(--dim-gray);
      font-size: $fs-small;
      font-weight: $fw-regular;
    }
    .tooltipSpan {
      min-height: 15px;
      min-width: 15px;
    }
  }
  .toggleMenu {
    box-shadow: var(--shadow) 0px 4px 12px;
    border: none;
    padding-inline: 12px;
    .dropdown-item {
      &:hover {
        border-radius: 10px;
      }
      &:active {
        background-color: var(--snow);
      }
    }
  }
}