.infoCard {
  @include align-justify-center;
  background-color: var(--white);
  width: 100%;
  border-radius: 15px;
  padding: 100px 50px;

  .content {
    font-size: $fs-large;
    font-weight: $fw-medium;
    text-align: center;
  }
}