.my-profile-wrapper {
    .profile-pic-section {
        padding: 40px;
        @include align-center;
        gap: 38px;

        .avatar-image {
            margin-right: 0;
            width: 125px;
            height: 125px;

            .img-overlay {
                right: 12px;
                bottom: 5px;
            }

            .pro-pic {
                width: 125px;
                height: 125px;
            }
        }

        .profile-details {
            .name {
                font-size: $fs-h4-big;
                line-height: 36px;
            }

            .email {
                font-size: $fs-small;
                line-height: 21px;
                margin-bottom: 15px;
            }
        }
    }

    .groups {
        display: flex;
        gap: 30px;
        padding-bottom: 25px;

        &:last-child {
            padding-bottom: 5px;
        }

        .group-item {
            width: 100%;

            .custom-input {
                @include align-center;
                background-color: var(--white);
            }
        }
    }

    .accordion {
        padding: 0 26px;
        padding-bottom: 6px;

        &:last-child {
            padding-bottom: 33px;
        }

        .accordion-item {
            border-radius: 0;
            background-color: var(--snow);
            border: 0;

            .accordion-header {
                .accordion-button {
                    background-color: transparent;
                    padding: 25px;
                    font-size: $fs-big;
                    font-weight: $fw-medium;

                    &:focus {
                        box-shadow: unset;
                    }
                }
            }

            .accordion-body {
                border-top: 1px solid var(--light-grey);
                padding: 25px;

                .submit-action {
                    display: flex;
                    gap: 26px;
                }
            }
        }
    }
}

.profile-circle {
    width: 125px;
    height: 125px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 50%;
    font-size: $fs-XXXL;
    font-weight: $fw-medium;
    background-color: var(--traffic-white);
}

.profile-circle-md {
    &.responsibilityprofile-circle{
        font-size:$fs-medium; 
    }
    width: 85px;
    height: 85px;
    font-size: $fs-XXL;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 50%;
    font-weight: $fw-medium;
    background-color: var(--traffic-white);
}

@media only screen and (max-width: 575px) {
    .my-profile-wrapper {
        .groups {
            flex-direction: column;
        }
    }
}

@media only screen and (max-width: 480px) {
    .my-profile-wrapper {
        .profile-pic-section {
            flex-direction: column;
            gap: 20px;
        }

        .accordion {
            padding: 0 15px;
            padding-bottom: 6px;

            &:last-child {
                padding-bottom: 33px;
            }

            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        padding: 15px;
                    }
                }

                .accordion-body {
                    padding: 15px;
                }
            }
        }
    }
}