.custom-btn {
    position: relative;
    height: 40px;
    border-radius: 34px;
    padding: 0 25px;
    min-width: 124px;
    @include align-justify-center;

    &.secondary-btn {
        border: 1px solid var(--selective-yellow);
        background-color: transparent;
        color: var(--selective-yellow);
    }

    &.primary-btn {
        border: 1px solid var(--selective-yellow);
        background-color: var(--selective-yellow);
        color: var(--white);  
    }

    .spinner{
        img {
            width: 40px;
        }
    }

    &:disabled {
        opacity: 0.5;
    }
}

//toggle-btn 
.toggle-button-wrapper {
    @include align-center;
    gap: 14px;
    height: 38px;
    background-color: var(--gainsboro);
    width: fit-content;
    padding: 0 5px;
    border-radius: 8px;

    button {
        border-radius: 6px;
        background-color: transparent;
        padding: 5px 10px;
        border: 0;
        line-height: 20px;
        font-size: $fs-small;
        transition: all 0.3s ease-in-out;

        &.active-btn-tab {
            background-color: var(--white);
            transition: all 0.3s ease-in-out;
        }
    }
}
