.filter-dropdown {
    position: relative;

    .filter-count {
        font-size: $fs-micro;
        font-weight: $fw-bolder;
        color: white;
        background-color: var(--selective-yellow);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        position: absolute;
        top: -10px;
        left: 27px;
    }
    .btn-group {
        height: 20px;

        .dropdown-toggle {
            background: transparent;
            border: 0;
            padding: 0;

            &::after {
                content: none;
            }

            &:active {
                background: transparent;
            }
        }
    }

    .dropdown-menu {
        width: 286px;
        border: 0;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
        border-radius: 10px;
        padding: 0;

        .dropdown-item {
            &:hover {
                background: transparent;
            }

            &:active {
                background: transparent;
                color: inherit;
            }

            &.filter-header {
                font-size: $fs-large;
                font-weight: $fw-medium;
                @include align-center-justify-between;
                padding: 20px 30px;
                border-bottom: 1px solid var(--light-grey);
                cursor: default;

                &:active {
                    color: inherit;
                }
            }
        }

        .filter-body {
            overflow: auto;
            max-height: 350px;

            .accordion {
                .accordion-item {
                    border: 0;

                    .accordion-button {
                        border-radius: 0;
                        padding: 14px 30px;
                        font-size: $fs-medium;
                        font-weight: $fw-medium;

                        &:not(.collapsed) {
                            background: var(--traffic-white);
                            box-shadow: unset;
                        }

                        &:focus {
                            background-color: var(--traffic-white);
                            box-shadow: none;
                        }
                    }

                    &:not(:last-child) {
                        .accordion-collapse {
                            border-bottom: 1px solid var(--light-grey);
                        }
                    }
                }

                .accordion-body {
                    padding: 0;

                    .sublist {
                        .sub-item {
                            font-size: $fs-small;
                            font-weight: $fw-regular;
                         
                            .list {
                                cursor: pointer;
                                padding: 14px 30px;
                                @include align-center-justify-between;
                              
                            }
                        }
                    }
                }
            }
        }
    }
}
label.list{
  text-transform: capitalize !important; 
}
button.accordion-button {
    text-transform: capitalize;
    // &::first-letter {
    //     text-transform: capitalize;
    //   }
   
}
@media only screen and (max-width: 360px) {
    .filter-dropdown {
        .dropdown-menu {
            width: 260px;
        }
    }
}