@mixin align-center {
    display: flex;
    align-items: center;
}

@mixin justify-center {
    display: flex;
    justify-content: center;
}

@mixin align-justify-center {
    @include align-center;
    justify-content: center;
}

@mixin row-align-justify-center {
    @include align-center;
    flex-direction: row;
}

@mixin align-center-justify-end {
    @include align-center;
    justify-content: flex-end;
}

@mixin align-center-justify-between {
    @include align-center;
    justify-content: space-between;
}

@mixin bg-Gradient($deg, $colVal_A, $colVal_B ) {
    background: linear-gradient($deg, var(--selective-yellow) $colVal_A, var(--safron) $colVal_B);
}

@mixin text-Gradient($colVal_A, $colVal_B ) {
    background: linear-gradient(to right, var(--selective-yellow) $colVal_A, var(--selective-yellow-2) $colVal_B);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin badge($bg, $text) {
    // height: 27px;
    // border-radius: 15px;
    border-radius: 10px;
    font-size: $fs-small;
    @include align-justify-center;
    width: max-content;
    // padding: 0 15px;
    padding: 5px;
    background-color: $bg;
    // color: $text;
    line-height: 24px;
    width: 116px;
    text-transform: capitalize;
    line-height: 17px;
    text-align: center;
}

@mixin scroll {
    &::-webkit-scrollbar {
        width: 6px;
        background-color: var(--white-smoke);
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 30px;
        @include bg-Gradient(3deg, 8.24%, 89.67%);
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: var(--white-smoke);
        border-radius: 30px;
    }
}