.setups {
    .custom-table {
        table {
            tbody {
                tr {
                    td {
                        .cell-data {
                            padding: 9px 10px;
                        }
                    }
                }

            }
        }
    }

    .sketch-picker {
        width: 180px !important;
    }

    .duration {
        @include align-center-justify-between;
    }

    .screen-actions {
        @include align-center;
        gap: 22px;

        .tab-group {
            width: 100%;
        }
    }

    .custom-table {
        tbody {
            tr {
                &.sub-row {
                    .description {
                        margin-top: 10px;
                        font-size: $fs-small;
                        line-height: 21px;
                    }
                }

                &:hover {
                    .actions {
                        // .responsibility-edit {
                        //     margin-right: 30px;
                        // }

                        .edit {
                            display: block;
                        }
                    }
                }

                .actions {
                    @include align-center;

                    &.responsibility-edit {
                        @include align-center;
                        gap: 80px;
                    }

                    .edit {
                        cursor: pointer;
                        width: 18px;
                    }
                }
                .label-cell{
                    font-weight: $fw-medium;
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .setups {
        .screen-actions {
            flex-direction: column;
            gap: 0;
            align-items: flex-start;
            margin-bottom: 22px;

            .tab-group {
                overflow: auto;
                overflow-y: hidden;
                margin-bottom: 20px;
            }
        }
    }
}